import { NumberInput, Stack } from "@mantine/core";
import { useEffect, useState } from "react";

export function CheckoutTypeAutoReload(props: { amount: number; onChange: (val: any) => void }) {
  const [paymentRequest, setPaymentRequest] = useState({
    automaticReloadPaymentRequest: {
      paymentDescription: "A automatic reload payment",
      billingAgreement: "Billing agreement",
      managementURL: "https://embryo.cellsoftware.co.uk",
      automaticReloadBilling: {
        amount: props.amount,
        label: "Automatic Reload",
        automaticReloadPaymentThresholdAmount: 150,
      },
    },
  });

  // Update paymentRequest
  useEffect(() => props.onChange(paymentRequest), [paymentRequest, props]);
  // Update Amount value
  useEffect(
    () =>
      setPaymentRequest({
        ...paymentRequest,
        automaticReloadPaymentRequest: {
          ...paymentRequest.automaticReloadPaymentRequest,
          automaticReloadBilling: {
            ...paymentRequest.automaticReloadPaymentRequest.automaticReloadBilling,
            amount: props.amount,
          },
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.amount]
  );

  return (
    <Stack>
      <NumberInput
        size="md"
        label="Payment Threshold Amount"
        description="The balance an account reaches before the merchant applies the automatic reload amount."
        defaultValue={0.4}
        decimalScale={2}
        min={0.4}
        step={0.25}
        max={999}
        value={
          paymentRequest.automaticReloadPaymentRequest.automaticReloadBilling.automaticReloadPaymentThresholdAmount /
          100
        }
        onChange={(e) => {
          const newValue = Math.trunc(Number(e) * 100);

          setPaymentRequest({
            ...paymentRequest,
            automaticReloadPaymentRequest: {
              ...paymentRequest.automaticReloadPaymentRequest,
              automaticReloadBilling: {
                ...paymentRequest.automaticReloadPaymentRequest.automaticReloadBilling,
                automaticReloadPaymentThresholdAmount: newValue,
              },
            },
          });
        }}
      />
    </Stack>
  );
}
