import { Fieldset, Group, NumberInput, Stack, Title, Text, NativeSelect } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import moment from "moment";
import { useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";

export function CheckoutTypeRecurring(props: { amount: number; onChange: (val: any) => void }) {
  const [selectedPaymentInterval, setSelectedPaymentInterval] = useState<string>("Minute");

  const [paymentRequest, setPaymentRequest] = useState({
    recurringPaymentRequest: {
      paymentDescription: "Embryo Subscription",
      regularBilling: {
        amount: props.amount,
        label: "Embryo Recurring",
        recurringPaymentStartDate: moment().add(15, "minute").toDate(),
        recurringPaymentEndDate: moment().add(1, "hour").toDate(),
        recurringPaymentIntervalUnit: "minute",
        recurringPaymentIntervalCount: 1,
      },
      billingAgreement: "This will be where a billing agreement will be displayed to the user.",
      managementURL: "https://embryo.cellsoftware.co.uk",
    },
  });

  // Update paymentRequest
  useEffect(() => props.onChange(paymentRequest), [paymentRequest, props]);
  // Update amount
  useEffect(
    () =>
      setPaymentRequest({
        ...paymentRequest,
        recurringPaymentRequest: {
          ...paymentRequest.recurringPaymentRequest,
          regularBilling: { ...paymentRequest.recurringPaymentRequest.regularBilling, amount: props.amount },
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.amount]
  );

  return (
    <Stack>
      {/* RECURRING DATE */}
      <Fieldset legend={<Title order={4}>Payment Timeframe</Title>}>
        <Stack>
          <Text c="dimmed">The start and end date of the recurring payment.</Text>
          <Group grow align="flex-end">
            <DateTimePicker
              label="Start Date"
              size="md"
              valueFormat="DD MMM YYYY HH:mm"
              minDate={moment().add(30, "minute").toDate()}
              dropdownType={isDesktop ? "popover" : "modal"}
              value={paymentRequest.recurringPaymentRequest.regularBilling.recurringPaymentStartDate}
              onChange={(d) => {
                setPaymentRequest({
                  ...paymentRequest,
                  recurringPaymentRequest: {
                    ...paymentRequest.recurringPaymentRequest,
                    regularBilling: {
                      ...paymentRequest.recurringPaymentRequest.regularBilling,
                      recurringPaymentStartDate: moment(d).toDate(),
                    },
                  },
                });
              }}
            />
            <DateTimePicker
              label="End Date"
              size="md"
              valueFormat="DD MMM YYYY HH:mm"
              minDate={moment(paymentRequest.recurringPaymentRequest.regularBilling.recurringPaymentStartDate)
                .add(30, "minute")
                .toDate()}
              dropdownType={isDesktop ? "popover" : "modal"}
              value={paymentRequest.recurringPaymentRequest.regularBilling.recurringPaymentEndDate}
              onChange={(d) => {
                setPaymentRequest({
                  ...paymentRequest,
                  recurringPaymentRequest: {
                    ...paymentRequest.recurringPaymentRequest,
                    regularBilling: {
                      ...paymentRequest.recurringPaymentRequest.regularBilling,
                      recurringPaymentEndDate: moment(d).toDate(),
                    },
                  },
                });
              }}
            />
          </Group>
        </Stack>
      </Fieldset>

      {/* PAYMENT INTERVAL */}
      <Fieldset legend={<Title order={4}>Payment Interval</Title>}>
        <Stack>
          <Group grow align="flex-end">
            <NumberInput
              label="Number of Intervals"
              size="md"
              min={1}
              max={99}
              value={paymentRequest.recurringPaymentRequest.regularBilling.recurringPaymentIntervalCount}
              onChange={(i) =>
                setPaymentRequest({
                  ...paymentRequest,
                  recurringPaymentRequest: {
                    ...paymentRequest.recurringPaymentRequest,
                    regularBilling: {
                      ...paymentRequest.recurringPaymentRequest.regularBilling,
                      recurringPaymentIntervalCount: Number(i),
                    },
                  },
                })
              }
            />
            <NativeSelect
              label="Duration"
              size="md"
              data={["Minute", "Hour", "Day", "Month"]}
              value={selectedPaymentInterval}
              onChange={(e) => {
                setSelectedPaymentInterval(e.currentTarget.value);
                switch (e.currentTarget.value) {
                  case "Hour":
                    setPaymentRequest({
                      ...paymentRequest,
                      recurringPaymentRequest: {
                        ...paymentRequest.recurringPaymentRequest,
                        regularBilling: {
                          ...paymentRequest.recurringPaymentRequest.regularBilling,
                          recurringPaymentIntervalUnit: "hour",
                        },
                      },
                    });
                    break;
                  case "Day":
                    setPaymentRequest({
                      ...paymentRequest,
                      recurringPaymentRequest: {
                        ...paymentRequest.recurringPaymentRequest,
                        regularBilling: {
                          ...paymentRequest.recurringPaymentRequest.regularBilling,
                          recurringPaymentIntervalUnit: "day",
                        },
                      },
                    });
                    break;
                  case "Month":
                    setPaymentRequest({
                      ...paymentRequest,
                      recurringPaymentRequest: {
                        ...paymentRequest.recurringPaymentRequest,
                        regularBilling: {
                          ...paymentRequest.recurringPaymentRequest.regularBilling,
                          recurringPaymentIntervalUnit: "month",
                        },
                      },
                    });
                    break;
                  default:
                    setPaymentRequest({
                      ...paymentRequest,
                      recurringPaymentRequest: {
                        ...paymentRequest.recurringPaymentRequest,
                        regularBilling: {
                          ...paymentRequest.recurringPaymentRequest.regularBilling,
                          recurringPaymentIntervalUnit: "minute",
                        },
                      },
                    });
                    break;
                }
              }}
            />
          </Group>
          <Text>{`There will be ${
            paymentRequest.recurringPaymentRequest.regularBilling.recurringPaymentIntervalCount
          } payment${
            paymentRequest.recurringPaymentRequest.regularBilling.recurringPaymentIntervalCount > 1 ? "s" : ""
          } every ${selectedPaymentInterval.toLowerCase()}.`}</Text>
        </Stack>
      </Fieldset>
    </Stack>
  );
}
