import Main from "../pages/main";
import "@mantine/core/styles.css";
import { AppShell, Container, Flex, Switch, Text } from "@mantine/core";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ReactComponent as CellSoftwareLogo } from "../images/cell-software-logo.svg";
import { useState } from "react";
import CheckoutAmount from "../pages/Checkout/EnterAmount";

export default function AppShellRoot() {
  const [isTestMode, setTestMode] = useState(false);
  const [isTestSwitchDisabled, setIsTestSwitchDisabled] = useState(false);

  return (
    <BrowserRouter>
      <AppShell header={{ height: 64 }} padding="md">
        <AppShell.Header p="md">
          <Container>
            <Flex justify="space-between" align="center">
              <Flex darkHidden>
                <CellSoftwareLogo height={28} fill="black" />
              </Flex>
              <Flex lightHidden>
                <CellSoftwareLogo height={28} fill="white" />
              </Flex>
              <Flex gap="xs">
                <Text fw={800} c={isTestMode ? "orange" : "blue"}>
                  {isTestMode ? "TEST" : "LIVE"}
                </Text>
                <Switch
                  color="orange"
                  disabled={isTestSwitchDisabled}
                  checked={isTestMode}
                  size="md"
                  fw={300}
                  onChange={(event) => setTestMode(event.currentTarget.checked)}
                />
              </Flex>
            </Flex>
          </Container>
        </AppShell.Header>
        <AppShell.Main>
          <Routes>
            <Route
              path="/"
              index
              element={
                <Container>
                  <Main isTestMode={isTestMode} />
                </Container>
              }
            />
            <Route
              path="/checkout"
              index
              element={
                <Container>
                  <CheckoutAmount isTestMode={isTestMode} setIsTestSwitchDisabled={setIsTestSwitchDisabled} />
                </Container>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </AppShell.Main>
      </AppShell>
    </BrowserRouter>
  );
}
