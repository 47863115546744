import {
  Button,
  Container,
  NumberInput,
  Text,
  SegmentedControl,
  Stack,
  Input,
  Transition,
  ScrollArea,
  NativeSelect,
  Flex,
} from "@mantine/core";
import CheckoutRoot from "./Root";
import { useEffect, useState } from "react";
import { IS_IN_DEVELOPMENT, STRIPE_API_KEY_LIVE, STRIPE_API_KEY_TEST } from "../../services/env-process";
import { CheckoutTypeRecurring } from "./types/Recurring";
import { ApplePayOption } from "@stripe/stripe-js/types/stripe-js/elements/apple-pay";
import { CheckoutTypeAutoReload } from "./types/AutoReload";
import { CheckoutTypeDeferred } from "./types/Deferred";
import { isSafari } from "react-device-detect";
import moment from "moment";

export default function CheckoutAmount(props: {
  isTestMode: boolean;
  setIsTestSwitchDisabled: (enabled: boolean) => void;
}) {
  const stripeSecretApi = (props.isTestMode ? STRIPE_API_KEY_TEST : STRIPE_API_KEY_LIVE)!;
  const [currency, setCurrency] = useState<string>("gbp");
  const [amount, setAmount] = useState<number>(40);
  const transitionDuration = 200;
  // The current options for Apple Pay
  const [paymentOptions, setPaymentOptions] = useState<ApplePayOption>();
  // All available payment types
  const paymentTypes = [
    { title: "Standard", subtitle: "Payment", value: "payment" },
    { title: "Recurring", subtitle: "MPAN", value: "recurring" },
    { title: "Auto Reload", subtitle: "MPAN", value: "reload" },
    { title: "Deferred", subtitle: "MPAN", value: "deferred" },
  ];
  // The default payment type value
  const paymentTypeDefault = paymentTypes[0].value;
  // The current payment type selected
  const [paymentType, setPaymentType] = useState(paymentTypeDefault);
  // Determines whether checkout is shown to the user
  const [showCheckout, setShowCheckout] = useState(false);
  // All available currencies
  const currencyList = [
    { value: "gbp", label: "GBP" },
    { value: "usd", label: "USD" },
    { value: "eur", label: "EUR" },
    { value: "kzt", label: "KZT" },
    { value: "sek", label: "SEK" },
    { value: "czk", label: "CZK" },
  ];

  useEffect(() => {
    return () => {
      props.setIsTestSwitchDisabled(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkPaymentType = () => {
    if (paymentType === "payment") {
      // clear any payment options that shouldn't be there
      setPaymentOptions(undefined);
    } else if (
      paymentType === "recurring" &&
      moment(paymentOptions?.recurringPaymentRequest?.regularBilling?.recurringPaymentStartDate).isBefore(moment())
    ) {
      // check date is in the future
      alert("The Start Date of the recurring payment must be in the future");
      return;
    }

    // console.log(paymentOptions);
    props.setIsTestSwitchDisabled(true);
    setShowCheckout(true);
  };

  const continueButton = () => {
    return (
      <Button fullWidth size="md" color={props.isTestMode ? "orange" : "blue"} onClick={checkPaymentType}>
        Next
      </Button>
    );
  };

  return (
    <Container size="xs">
      <Stack>
        <Flex gap="md" align="flex-end">
          <NumberInput
            size="md"
            label="Amount"
            description="Must be more than £0.35"
            disabled={showCheckout}
            defaultValue={0.4}
            decimalScale={2}
            min={0.4}
            step={0.05}
            max={999}
            value={amount / 100}
            onChange={(e) => {
              const newValue = Number(e) * 100;
              setAmount(Math.trunc(newValue));
            }}
          />
          <NativeSelect
            size="md"
            label="Currency"
            placeholder="GBP"
            disabled={showCheckout}
            data={currencyList}
            w={100}
            value={currency}
            onChange={(e) => setCurrency(e.currentTarget.value)}
          />
        </Flex>

        {/* Load Checkout */}
        <Transition
          mounted={showCheckout}
          transition="scale-y"
          enterDelay={transitionDuration}
          duration={transitionDuration}
          timingFunction="ease"
        >
          {(styles) => (
            <div style={styles}>
              <CheckoutRoot
                apiKey={stripeSecretApi}
                amount={amount}
                currency={currency}
                isTestMode={props.isTestMode}
                options={paymentOptions}
                onCancel={() => {
                  props.setIsTestSwitchDisabled(false);
                  setShowCheckout(false);
                }}
              />
            </div>
          )}
        </Transition>

        {/* Payment Types */}
        <Transition
          mounted={!showCheckout}
          transition="scale-y"
          enterDelay={transitionDuration}
          duration={transitionDuration}
          timingFunction="ease"
          keepMounted
        >
          {(styles) => (
            <Stack style={styles}>
              {(isSafari || IS_IN_DEVELOPMENT) && (
                <ScrollArea>
                  <Input.Wrapper size="md" label="Payment Type">
                    <SegmentedControl
                      fullWidth
                      size="md"
                      radius="md"
                      value={paymentType}
                      onChange={(v) => {
                        setPaymentType(v);
                        if (v === paymentTypeDefault) {
                          setPaymentOptions(undefined);
                        }
                      }}
                      data={paymentTypes.map((t) => {
                        return {
                          value: t.value,
                          label: (
                            <Stack gap={0} align="start">
                              <Text>{t.title}</Text>
                              <Text fz="xs" c="dimmed">
                                {t.subtitle}
                              </Text>
                            </Stack>
                          ),
                        };
                      })}
                    />
                  </Input.Wrapper>
                </ScrollArea>
              )}

              <Transition
                mounted={paymentType === "payment"}
                transition="scale-y"
                enterDelay={transitionDuration}
                duration={transitionDuration}
                timingFunction="ease"
                keepMounted
              >
                {(styles) => <div style={styles}>{continueButton()}</div>}
              </Transition>

              <Transition
                mounted={paymentType === "recurring"}
                transition="scale-y"
                enterDelay={transitionDuration}
                duration={transitionDuration}
                timingFunction="ease"
                keepMounted
              >
                {(styles) => (
                  <Stack style={styles}>
                    <CheckoutTypeRecurring
                      amount={amount}
                      onChange={(pr) => {
                        if (paymentType === "recurring") {
                          setPaymentOptions(pr);
                        }
                      }}
                    />
                    {continueButton()}
                  </Stack>
                )}
              </Transition>

              <Transition
                mounted={paymentType === "reload"}
                transition="scale-y"
                enterDelay={transitionDuration}
                duration={transitionDuration}
                timingFunction="ease"
                keepMounted
              >
                {(styles) => (
                  <Stack style={{ ...styles }}>
                    <CheckoutTypeAutoReload
                      amount={amount}
                      onChange={(pr) => {
                        if (paymentType === "reload") {
                          setPaymentOptions(pr);
                        }
                      }}
                    />
                    {continueButton()}
                  </Stack>
                )}
              </Transition>

              <Transition
                mounted={paymentType === "deferred"}
                transition="scale-y"
                enterDelay={transitionDuration}
                duration={transitionDuration}
                timingFunction="ease"
                keepMounted
              >
                {(styles) => (
                  <Stack style={{ ...styles }}>
                    <CheckoutTypeDeferred
                      onChange={(pr) => {
                        if (paymentType === "deferred") {
                          setPaymentOptions(pr);
                        }
                      }}
                    />
                    {continueButton()}
                  </Stack>
                )}
              </Transition>
            </Stack>
          )}
        </Transition>
      </Stack>
    </Container>
  );
}
