import { Fieldset, NumberInput, Stack } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import moment from "moment";
import { useEffect, useState } from "react";

export function CheckoutTypeDeferred(props: { onChange: (val: any) => void }) {
  const [paymentRequest, setPaymentRequest] = useState({
    deferredPaymentRequest: {
      paymentDescription: "A deferred payment",
      managementURL: "https://embryo.cellsoftware.co.uk",
      deferredBilling: {
        amount: 100,
        label: "Embryo Deferred Fee",
        deferredPaymentDate: moment().add(15, "minute").toDate(),
      },
    },
  });

  // Update paymentRequest
  useEffect(() => props.onChange(paymentRequest), [paymentRequest, props]);

  return (
    <Fieldset>
      <Stack>
        <NumberInput
          size="md"
          label="Payment Threshold Amount"
          description="The balance an account reaches before the merchant applies the automatic reload amount."
          defaultValue={0.4}
          decimalScale={2}
          min={0.4}
          step={0.25}
          max={999}
          value={paymentRequest.deferredPaymentRequest.deferredBilling.amount / 100}
          onChange={(e) => {
            const newValue = Math.trunc(Number(e) * 100);

            setPaymentRequest({
              ...paymentRequest,
              deferredPaymentRequest: {
                ...paymentRequest.deferredPaymentRequest,
                deferredBilling: {
                  ...paymentRequest.deferredPaymentRequest.deferredBilling,
                  amount: newValue,
                },
              },
            });
          }}
        />

        <DateInput
          label="Deferred Payment Date"
          description="The date the payment will be taken."
          size="md"
          minDate={moment().add(15, "minute").toDate()}
          value={paymentRequest.deferredPaymentRequest.deferredBilling.deferredPaymentDate}
          onChange={(d) =>
            setPaymentRequest({
              ...paymentRequest,
              deferredPaymentRequest: {
                ...paymentRequest.deferredPaymentRequest,
                deferredBilling: {
                  ...paymentRequest.deferredPaymentRequest.deferredBilling,
                  deferredPaymentDate: moment(d).toDate(),
                },
              },
            })
          }
        />
      </Stack>
    </Fieldset>
  );
}
