import { Button, Stack, Text, Grid, Alert, Transition } from "@mantine/core";
import { IconCircleCheckFilled } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function Main(props) {
  const [searchParams] = useSearchParams();

  const payments = [
    {
      title: "GBP",
      url_live: "https://buy.stripe.com/3cs2af1blaexc0g6oz",
      url_test: "https://buy.stripe.com/test_dR65mMgXNfed6pa9AB",
    },
    {
      title: "USD",
      url_live: "https://buy.stripe.com/cN26qvaLV72l7K05ku",
      url_test: "https://buy.stripe.com/test_4gw2aA9vl8PP9Bm3ce",
    },
    {
      title: "EUR",
      url_live: "https://buy.stripe.com/cN2eX17zJ1I17K04gp",
      url_test: "https://buy.stripe.com/test_6oE02scHxfedfZKdQT",
    },
    {
      title: "KZT",
      url_live: "https://buy.stripe.com/14kcOTaLVeuN1lCaET",
      url_test: "https://buy.stripe.com/test_aEU2aA4b1ea95l68wA",
    },
    { title: "SEK", url_live: "https://buy.stripe.com/fZeeX1dY7dqJ2pG5kw" },
    { title: "CZK", url_live: "https://buy.stripe.com/fZe4ing6f9at5BS4gt" },
  ];

  const [isSuccessful, setIsSuccessful] = useState(JSON.parse(searchParams.get("success")) ?? false);

  useEffect(() => {
    if (isSuccessful) {
      setTimeout(function () {
        setIsSuccessful(false);
      }, 4000);
    }
  }, [isSuccessful]);

  return (
    <Stack>
      <Transition mounted={isSuccessful} transition="pop" duration={1000} timingFunction="ease">
        {(styles) => (
          <Alert
            style={styles}
            variant="filled"
            color="green"
            title="Payment Successful"
            icon={<IconCircleCheckFilled />}
          />
        )}
      </Transition>
      <Text fz="lg">
        Click on a currency to be redirected to Stripe's Checkout. All products are set to the lowest amount Stripe
        accepts, but feel free to change it. You will be redirected back here upon completion.
      </Text>
      <Grid>
        {payments
          // eslint-disable-next-line no-self-compare
          .filter((i) => (props.isTestMode ? i.url_test : i.url_live))
          .map((b) => {
            return (
              <Grid.Col key={b.title} span={{ base: 12, sm: 6, md: 3, lg: 4 }}>
                <Button
                  fullWidth
                  radius="md"
                  size="lg"
                  fw={800}
                  leftSection={b.icon}
                  color={props.isTestMode ? "orange" : "blue"}
                  onClick={() =>
                    window.open(props.isTestMode ? b.url_test : b.url_live, "_self", "noopener,noreferrer")
                  }
                >
                  {b.title}
                </Button>
              </Grid.Col>
            );
          })}
      </Grid>
    </Stack>
  );
}
