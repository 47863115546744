import axios from "axios";
import { API_URL } from "../services/env-process";

/**
 * Calls the Portal API to generate a `Payment Intent` from Stripe.
 *
 * @param apiKey - Stripe's secret API key to use
 * @param amount - The amount to pay. Must be a whole `Number`. E.g. `40` Stripe will convert to £0.40 / `110` Stripe will convert to £1.10
 * @param currency - The currency to charge. E.g. `gbp` | `usd` | `eur`
 * @returns Either an error from Stripe or the `client_secret`
 *
 */
export async function checkoutCreateIntent(apiKey: String, amount: number, currency: string) {
  const url = `${API_URL}/checkout/create-intent?apiKey=${apiKey}&amount=${amount}&currency=${currency}`;
  const res = await axios.post(url);
  return res.data;
}
