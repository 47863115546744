import { useEffect, useState } from "react";
import { ExpressCheckoutElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button, Container, Group, Stack, Text } from "@mantine/core";
import * as apiService from "../../services/api-service";
import { StripeExpressCheckoutElementOptions, StripePaymentElementOptions } from "@stripe/stripe-js";
import { IS_IN_DEVELOPMENT, STRIPE_SECRET_API_KEY_LIVE, STRIPE_SECRET_API_KEY_TEST } from "../../services/env-process";
import { ApplePayOption } from "@stripe/stripe-js/types/stripe-js/elements/apple-pay";
import { isSafari } from "react-device-detect";

export default function CheckoutConfirm(props: {
  apiKey: string;
  amount: number;
  currency: string;
  isTestMode: boolean;
  onCancel: () => void;
  options?: ApplePayOption;
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);
  const [stripeSecretApiKey] = useState<string>(
    (props.isTestMode ? STRIPE_SECRET_API_KEY_TEST : STRIPE_SECRET_API_KEY_LIVE)!
  );

  const [message, setMessage] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const [expressPaymentOptions] = useState<StripeExpressCheckoutElementOptions>({
    paymentMethodOrder: ["apple_pay", "google_pay"],
    buttonType: { applePay: "buy" },
    wallets: { applePay: "always", googlePay: "always" },
    buttonTheme: { applePay: "black" },
  });
  const [paymentOptions] = useState<StripePaymentElementOptions>({
    layout: "accordion",
    paymentMethodOrder: ["apple_pay", "google_pay", "card", "klarna"],
    applePay: props.options,
    business: { name: "Embryo" },
  });

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe]);

  const payNow = async () => {
    if (!stripe || !elements) {
      setMessage("stripe or elements were NIL");
      return;
    }

    setIsLoading(true);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setIsLoading(false);
      setMessage(submitError.message);
      return;
    }

    apiService.checkoutCreateIntent(stripeSecretApiKey, props.amount, props.currency).then(async (data) => {
      const clientSecret = data.client_secret;

      if (clientSecret) {
        setClientSecret(clientSecret);

        const { error } = await stripe.confirmPayment({
          elements,
          clientSecret: clientSecret,
          confirmParams: {
            return_url: "https://embryo.cellsoftware.co.uk/?success=true",
          },
        });

        setIsLoading(false);

        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occurred.");
        }
      } else {
        // error
        setIsLoading(false);
      }
    });
  };

  return (
    <Container size="xs">
      <Stack>
        {/* Show express buttons (Apple and Google) */}
        {!props.options && <ExpressCheckoutElement onConfirm={payNow} options={expressPaymentOptions} />}
        {/* Show card details */}
        {((props.options && isSafari) || IS_IN_DEVELOPMENT) && (
          <PaymentElement id="payment-element" options={paymentOptions} />
        )}

        <Text fz="xs" c="dimmed">
          Providers could take up to 30 seconds to appear. Anymore, contact the developer.
        </Text>

        <Group grow>
          <Button
            size="lg"
            disabled={isLoading}
            color={props.isTestMode ? "orange" : "blue"}
            variant="outline"
            onClick={props.onCancel}
          >
            Cancel
          </Button>
          {props.options !== undefined && (
            <Button
              size="lg"
              loading={isLoading || !stripe || !elements}
              color={props.isTestMode ? "orange" : "blue"}
              onClick={payNow}
            >
              Pay Now
            </Button>
          )}
        </Group>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </Stack>
    </Container>
  );
}
